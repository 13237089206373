<template >
  <div class="table-wrap">
    <div class="table-container" style="margin-bottom:24px;">
      <a-row :gutter="16" justify="space-between">
        <a-col v-for="(item,index) in lists" :key="index" :span="8">
          <a-card :title="item.name" hoverable :bordered="true" style="width: 100%;" :headStyle="black">
            <template #extra>价格：￥{{item.price}}</template>
            <a-divider>政策详情：{{item.policy_detail}} 条/天</a-divider>
            <a-divider>政策匹配：{{item.policy_detail}} 次/月</a-divider>
            <a-divider>专利查询：{{item.policy_detail}} 次/天</a-divider>
            <a-divider>AI机器人：{{item.policy_detail}} 次/天</a-divider>
            <a-divider>子账户数量：{{item.policy_detail}} 次/天</a-divider>
            <a-divider>积分赠送：{{item.policy_detail}} </a-divider>

            <a-button type="primary"  @click="getBuyQrcode(item.id,item.price)" :block="true">立即购买</a-button>
          </a-card>
        </a-col>
      </a-row>
      
      </div>

      <a-modal
        v-model:visible="visible"
        title="套餐支付"
        
        :footer="null"
        wrap-class-name="full-modal"
        @ok="handleOk"
      >
      <div class="text-center">
        <qrcode-vue :value="paycode" :size="200" level="H" />
        <p>微信扫码支付</p>
          <p style="font-size:18px;" class="text-primary"><b>{{priceTitle}}：￥{{price}}</b></p>
          
          <p v-if="!hadvalidate">
            <a-input-group compact>
              <a-input v-model:value="mobile" style="width: calc(100% - 200px)" maxlength="11" size="large" placeholder="请输入优惠码" />
              <a-button type="primary" size="large" @click="verificate()">立即核验</a-button>
            </a-input-group>
          </p>
          <p v-else>
              <span class="bg-danger px-5 py-1 text-white rounded">核验成功</span>
              <!-- <a-button type="danger" class="px-5" size="large">核验成功</a-button> -->

          </p>
        </div>
      </a-modal>
      
  </div>
</template>
<script>
//import { usePagination } from 'vue-request';
import { defineComponent,ref,onBeforeUnmount } from 'vue';
//import {Card} from 'ant-design-vue';
import service from '@/service/service';
//import { HomeOutlined,FullscreenOutlined } from '@ant-design/icons-vue';
// import { message, Modal } from 'ant-design-vue';
//import { HolderOutlined } from '@ant-design/icons-vue';
import QrcodeVue from 'qrcode.vue'
import { mapState } from 'vuex';
import { message } from 'ant-design-vue';
// 定义scoket
//const socket = ref(null)


export default defineComponent({
  components: {
    //HolderOutlined,
    //CaretDownOutlined
    //AButton: Button,
    //HomeOutlined,
    //FullscreenOutlined
    //ACard:Card
    QrcodeVue
  },
  

  data() {
    return {
      lists:null,
      paycode:"aaa",
      price:0,
      orderSn:"",
      mobile:"",
      hadvalidate:0,
      priceTitle:"套餐价格"
    }
  },
  watch: {
    
  },
  created() {
    this.getPackages()
  },
 
  computed: {
    ...mapState(['userinfo']),
  },
  methods: {
    
    async getPackages(){
        // 声明并调用一次数据拉取
      await service.get_package_lists().then(res=>{
        this.lists = res.data
      });

      

      //this.current = current
      
      ///console.log(this.lists)

    },

    async verificate(){
      let params={
        order_sn: this.orderSn,
        mobile:this.mobile
      }
      const res = await service.verificate_phone(params)
      if(res.code == 1){
        this.paycode = res.data.url
        this.orderSn = res.data.order_sn
        this.price = res.data.total_amount
        this.hadvalidate = 1
        this.priceTitle = "套餐优惠价"
       // message.info(res.msg)
      }


    },
    
    

    async getBuyQrcode(id,price){
      //console.log(this.userinfo);
      this.hadvalidate = 0
      this.mobile = ""
      this.price = price
      await service.get_buy_qrcode({vip_id:id,user_id:this.userinfo.userid}).then(res=>{
        if(res.code){
          this.paycode = res.data.url
          this.visible = true;
          this.orderSn = res.data.order_sn
          //二维码展示后创建socket
          var timer;
          timer = setInterval(() => {
            service.get_pay_result({order_sn: this.orderSn}).then(res2=>{
              if(res2.code){
                this.visible = false;
                
                clearInterval(timer)
                this.$router.replace('/work/home')
              }else{
                //this.visible = false;
                //message.close
                if(this.visible==false){
                  clearInterval(timer)
                }
                message.destroy();
                return false
              }
            })
          },1000*3)

          //open()
        }else{
          //message.error('失败');
        }
        

      }

      )
       
      }
 


  },

  setup() {
    const visible = ref(false);
    

    
    return {
      visible,
    };
  },

})

// onMounted(() => {
//   // 微信支付二维码接口
  
//     // 二维码展示成功后开始建立websocket连接
//     open()
  
// })


// 关闭websocket连接   onclose()方法
// const close = ()=>{
//   socket.value?.close()
//   socket.value.onclose=()=>{
//     console.log('关闭了');
//   }
//   socket.value = null
// }
 
// 打开websocket   onopen()方法
// const open = () => {
//   // 注意wss和ws 协议是http的使用ws，是https的使用wss
//   socket.value = new WebSocket('wss://www.qzn365.com/home/pay/getPayResult')
//   socket.value.onopen=()=>{
//     // 接收消息方法
//     //message()
//     // 建立心跳机制防止无操作websocket断开连接 send()方法
//     setInterval(() => {
//       const data = {order_sn: this.orderSn} // 给后端传参 无要求可随意
//       socket.value.send(JSON.stringify(data))
//     },1000*30)
//   }
// }
 

 
// 页面销毁时记得断开websocket连接
onBeforeUnmount(() => {
  close()
})

</script>
<style lang="less" scoped>
:deep .ant-card-head{
  background: #003466;
  color: #fff;
  font-size:24px

}
:deep .ant-card-extra{
    font-size: 18px!important;
    color: #ffff00;
  }
:deep .ant-divider-inner-text{
  font-size: 22px;
  color: #666;
  line-height: 1.5;
  padding-bottom: 10px;
}

.text-center{
  text-align: center;
}


</style>